import React from "react";
import "./TechSection.scss";
import TechOne from "../assets/tech1.png";
import TechTwo from "../assets/tech2.png";

export function TechSection({ data }) {
  return (
    <section className="TechSection">
      <div className="Content">
        <div className="Descriptions">
          <h2>Generative AI Technology</h2>
          <p>生成AI技術とは</p>
          <p>{data.tech_section_1}</p>
          <p>{data.tech_section_2}</p>
          <p>{data.tech_section_3}</p>
        </div>
        <div className="Images">
          <div className="Image">
            <img src={TechOne} alt="Generative AI Technology" />
            <div>
              <strong>出典:</strong> A Style-Based Generator Architecture for
              Generative Adversarial Networks.
            </div>
          </div>
          <div className="Image">
            <img src={TechTwo} alt="Generative AI Technology" />
            <div>
              Stable Diffusionを使い “A realistic painting of traditional
              Japanese pottery used to drink matcha tea”
              というプロンプトで生成された画像
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
