import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { HomePage } from "./components/HomePage";
import { NewsListPage } from "./components/NewsListPage";
import { NewsPage } from "./components/NewsPage";
import { NotFoundPage } from "./components/NotFoundPage";

const router = createBrowserRouter([
  {
    path: "/news/:id",
    element: <NewsPage />,
  },
  {
    path: "/news",
    element: <NewsListPage />,
  },
  {
    path: "/",
    element: <HomePage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "*",
    element: <NotFoundPage />,
    errorElement: <NotFoundPage />,
  },
]);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
