import React from "react";
import "./FeaturedProjectSection.scss";
import AsahiyakiLogo from "../assets/asahiyaki_logo.png";
import AsahiyakiOne from "../assets/asa1.png";
import AsahiyakiTwo from "../assets/asa2.png";
// import Timeline from "../assets/timeline.png";
import QosmoLogo from "../assets/qosmo_logo.png";
import { Timeline } from "./Timeline";

export function FeaturedProjectSection({ data }) {
  return (
    <section className="FeaturedProjectSection">
      <div className="Content">
        <h2>
          Project Vol <span>01</span>
        </h2>
        <div className="Description">{data.featured_project_description}</div>
        <img
          className="AsahiyakiLogo"
          src={AsahiyakiLogo}
          alt="Asahiyaki Logo"
        />
        <div className="Main">
          <div className="AsahiyakiOne">
            <img src={AsahiyakiOne} alt="Asahiyaki Pot" />
            <span>
              朝日焼の根底にある「綺麗寂び」という茶人小堀遠州の美意識を基に、茶道具としての茶盌や茶入、水指、花入などを中心に朝日焼伝統の鹿背、紅鹿背をはじめ、十六世としての作風であり現代的な雰囲気をもつ月白釉流しの作品制作を行っている。海外での作品発表や、茶会の開催、茶の文化を広めるワークショップの開催なども積極的に行い、英国セントアイブスのリーチ窯での滞在制作及び作品発表、フランスパリのギメ東洋美術館での作品展示や茶会の開催の経験がある。
            </span>
          </div>
          <div className="AsahiyakiTwo">
            <img src={AsahiyakiTwo} alt="Asahiyaki Man" />
            <span className="AsahiyakiTwoDescription">朝日焼十六世窯元</span>
            <span className="AsahiyakiTwoName">松林 豊斎</span>
          </div>
        </div>
        <Timeline />
        <div className="Support">
          <span className="SupportedBy">Supported By</span>
          <img className="QosmoLogo" src={QosmoLogo} alt="Qosmo Logo" />
          <span className="QosmoDescription">
            「アートとテクノロジーを通じて人類の創造性を拡張する」をビジョンに掲げ、2009年設立。アーティスト、研究者、プログラマ、デザイナーから構成されるチームが、創造性のフロンティアを切り開く作品制作を通じて、多くの人が創造的に働き・学び・暮らせる社会の構築に寄与することをミッションに活動しています。
          </span>
        </div>
      </div>
    </section>
  );
}
