import "./NotFoundPage.scss";
import { useNavigate } from "react-router-dom";

export function NotFoundPage() {
  const navigate = useNavigate();
  return (
    <div>
      <div className="ErrorMessage">
        <h2>404</h2>
        <p>Page Not Found</p>
        <a href="/" onClick={() => navigate("/")}>
          Return Home
        </a>
      </div>
    </div>
  );
}
