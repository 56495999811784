import React from "react";
import "./Footer.scss";
import CTCLogoSmall from "../assets/CTC_logo_small.png";

export function Footer() {
  return (
    <div className="Footer">
      <div className="Description">
        NeuCraft project by 伊藤忠テクノソリューションズ株式会社
      </div>
      <img className="CTCLogo" src={CTCLogoSmall} alt="CTC logo" />
    </div>
  );
}
