import React, { useState, useEffect } from "react";
import { FeaturedProjectSection } from "./FeaturedProjectSection";
import { Footer } from "./Footer";
import { HeroSection } from "./HeroSection";
import { NewsSection } from "./NewsSection";
import { ProjectDescriptionSection } from "./ProjectDescriptionSection";
import { TechSection } from "./TechSection";
import { Error } from "./Error";
import { Meta } from "./MetaTags";

export function HomePage() {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    try {
      const res = await fetch(`https://neucraft.ai//wp-json/acf/v3/pages/30`);
      const _data = await res.json();
      setData(_data.acf);
    } catch (error) {
      console.error(error);
      setError(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="HomePage">
      <Meta />
      {error && <Error error={error} />}
      {data && (
        <>
          <HeroSection data={data} />
          <ProjectDescriptionSection data={data} />
          <NewsSection main />
          <FeaturedProjectSection data={data} />
          <TechSection data={data} />
          <Footer />
        </>
      )}
    </div>
  );
}
