import React from "react";
import "./NewsCard.scss";

export function NewsCard({ image, title, date, excerpt, onClick }) {
  return (
    <div onClick={onClick} className="NewsCard">
      <div
        style={{
          background: `url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="Image"
      />
      <div className="NewsCardTitle">{title}</div>
      <div className="NewsCardDate">{date}</div>
      {excerpt && (
        <div
          className="Excerpt"
          dangerouslySetInnerHTML={{ __html: excerpt }}
        />
      )}
    </div>
  );
}
