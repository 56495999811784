import React, { useState, useEffect } from "react";
import "./NewsListPage.scss";
import { Footer } from "./Footer";
import { Logo } from "./Logo";
import { NewsCard } from "./NewsCard";
import { useNavigate } from "react-router-dom";
import { Error } from "./Error";

export function NewsListPage() {
  const navigate = useNavigate();
  const [posts, setPosts] = useState(null);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const fetchPosts = async () => {
    try {
      const res = await fetch("https://neucraft.ai/wp-json/wp/v2/posts?_embed");
      const _posts = await res.json();
      setPosts(_posts);
    } catch (error) {
      console.error(error);
      setError(error);
    }
  };
  const fetchData = async () => {
    try {
      const res = await fetch(`https://neucraft.ai//wp-json/acf/v3/pages/30`);
      const _data = await res.json();
      setData(_data.acf);
    } catch (error) {
      console.error(error);
      setError(error);
    }
  };

  useEffect(() => {
    fetchPosts();
    fetchData();
  }, []);

  return (
    <div className="NewsListPage">
      {error && <Error error={error} />}
      {data && <Logo data={data.logo} />}
      <h1>News</h1>
      <div className="NewsContainer">
        {posts &&
          posts.map((post) => (
            <NewsCard
              className="NewsCard"
              key={post.id}
              image={
                post._embedded
                  ? post._embedded["wp:featuredmedia"][0].media_details.sizes
                      .medium_large.source_url
                  : ""
              }
              title={post.title.rendered}
              date={new Date(post.date).toLocaleDateString()}
              excerpt={post.excerpt.rendered}
              onClick={() => navigate(`/news/${post.id}`)}
            />
          ))}
      </div>
      <Footer />
    </div>
  );
}
