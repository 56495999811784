import React from "react";
import "./Logo.scss";
import { useNavigate } from "react-router-dom";

export function Logo({ data }) {
  const navigate = useNavigate();

  return (
    <div onClick={() => navigate("/")} className="Logo">
      <img src={data} alt="Logo" />
    </div>
  );
}
