import React, { useRef, useState } from "react";
import "./HeroSection.scss";
import ScrollDown from "../assets/arrow_circle_down.png";
import VolumeOff from "../assets/volume_off.png";
import VolumeOn from "../assets/volume_on.png";
import { Logo } from "./Logo";

export function HeroSection({ data }) {
  const ref = useRef();
  const [isMuted, setIsMuted] = useState(true);

  function toggleAudio() {
    setIsMuted(!isMuted);
    ref.current.muted = !ref.current.muted;
  }

  return (
    <section className="HeroSection">
      <Logo data={data.logo} />
      <div className="LogoSpace" />
      <div className="Video">
        {data && (
          <video
            ref={ref}
            width="100%"
            height="100%"
            playsInline
            autoPlay
            muted
            loop
          >
            <source src={data.video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
        <div className="VolumeToggle" onClick={toggleAudio}>
          {isMuted ? (
            <img src={VolumeOff} alt="Volume off" />
          ) : (
            <img
              style={{ filter: "brightness(0) invert(1)" }}
              src={VolumeOn}
              alt="Volume on"
            />
          )}
        </div>
      </div>
      <div className="ScrollDownContainer">
        <img src={ScrollDown} alt="Scroll Down" />
      </div>
    </section>
  );
}
