import React, { useRef, useState, useEffect } from "react";
import "./Timeline.scss";
import useOnScreen from "../utils/useOnScreen";
import Arrow from "../assets/arrow.png";

export function Timeline() {
  const ref = useRef();
  const isVisible = useOnScreen(ref);
  const [wasAnimated, setWasAnimated] = useState(false);

  useEffect(() => {
    if (isVisible && !wasAnimated) {
      setWasAnimated(true);
    }
  }, [isVisible, wasAnimated]);

  return (
    <div className="Timeline">
      <h2 ref={ref}>Timeline</h2>
      {wasAnimated && (
        <div className="StepsContainer">
          <div className="StepOne">
            <span className="StepTitle">Step 1</span>
            <span>
              <strong>データ収集</strong>
              <br />
              蔵に眠る朝日焼の歴史的作品を撮影
            </span>
          </div>
          <img className="ArrowOne" src={Arrow} alt="arrow" />
          <div className="StepTwo">
            <span className="StepTitle">Step 2</span>
            <span>
              <strong>機械学習モデル構築</strong>
              <br />
              収集した写真を深層学習アルゴリズムが学習
            </span>
          </div>
          <img className="ArrowTwo" src={Arrow} alt="arrow" />
          <div className="StepThree">
            <span className="StepTitle">Step 3</span>
            <span>
              <strong>新デザインの生成</strong>
              <br />
              AIモデルを使って新たな陶器のデザインを探索
            </span>
          </div>
          <img className="ArrowThree" src={Arrow} alt="arrow" />
          <div className="StepFour">
            <span className="StepTitle">Step 4</span>
            <span>
              <strong>制作</strong>
              <br /> 選ばれたデザインをもとにした新しい作品の制作
            </span>
          </div>
          <img className="ArrowFour" src={Arrow} alt="arrow" />
          <div className="StepFive">
            <span className="StepTitle">Step 5</span>
            <span>
              <strong>発表</strong>
            </span>
          </div>
        </div>
      )}
    </div>
  );
}
