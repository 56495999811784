import React, { useState, useEffect } from "react";
import "./NewsPage.scss";
import { Footer } from "./Footer";
import { Logo } from "./Logo";
import { useParams, useNavigate } from "react-router-dom";
import { NewsSection } from "./NewsSection";
import { Meta } from "./MetaTags";

export function NewsPage() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [post, setPost] = useState(null);
  const [data, setData] = useState(null);

  const fetchPost = async (id) => {
    const res = await fetch(
      `https://neucraft.ai/wp-json/wp/v2/posts/${id}?_embed`
    );
    const _post = await res.json();
    setPost(_post);
  };

  const fetchData = async () => {
    const res = await fetch(`https://neucraft.ai//wp-json/acf/v3/pages/30`);
    const _data = await res.json();
    setData(_data.acf);
  };

  useEffect(() => {
    setPost(null);
    fetchPost(id);
  }, [id]);

  useEffect(() => {
    fetchData();
  }, []);

  const getFeaturedImg = () => {
    if (!post._embedded) return "";
    return post._embedded["wp:featuredmedia"][0].media_details.sizes
      .medium_large.source_url;
  };

  return (
    <>
      {post && (
        <Meta
          title={post.title.rendered}
          description={post.excerpt.rendered}
          image={getFeaturedImg()}
        />
      )}
      <div key={id} className="NewsPage">
        {data && <Logo data={data.logo} />}
        {post && (
          <>
            <div className="NewsContainer" style={{ opacity: 1 }}>
              <div className="NewsLink" onClick={() => navigate("/news")}>
                News
              </div>
              <div
                style={{
                  background: `url(${getFeaturedImg()})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
                className="FeaturedImage"
              />
              <div className="Content">
                <div className="Title">{post.title.rendered}</div>
                <div className="Date">
                  {new Date(post.date).toLocaleDateString()}
                </div>
                <div
                  className="HTMLContent"
                  dangerouslySetInnerHTML={{ __html: post.content.rendered }}
                />
              </div>
            </div>
            <NewsSection excerpt={post.excerpt.rendered} />
            <Footer />
          </>
        )}
      </div>
    </>
  );
}
