import { Helmet } from "react-helmet";

export function Meta({ title, description, image }) {
  // Defaults
  if (!title) title = "NeuCraft";
  if (!description)
    description =
      "先端技術を使って伝統工芸に光を当てその魅力をより多くの人達に再発見してほしい」 Neucraft は先端的IT技術で社会問題の解決に取り組んできたCTCの新たな挑戦として、伝統工芸の未来のあり方を共創するプロジェクトです。";
  if (!image) image = "https://neucraft.ai/OGP.png";

  return (
    <Helmet>
      {/* <!-- HTML Meta Tags --> */}
      <title>{title}</title>
      <meta name="description" content={description} />

      {/* <!-- Facebook Meta Tags --> */}
      <meta property="og:url" content="https://neucraft.ai" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="NeuCraft" />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />

      {/* <!-- Twitter Meta Tags --> */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="neucraft.ai" />
      <meta property="twitter:url" content={image} />
      <meta name="twitter:title" content="NeuCraft" />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />

      {/* <!-- Meta Tags Generated via https://www.opengraph.xyz --> */}
    </Helmet>
  );
}
