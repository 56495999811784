import React from "react";
import "./ProjectDescriptionSection.scss";
import CTCLogo from "../assets/CTC_logo.png";

export function ProjectDescriptionSection({ data }) {
  return (
    <section className="ProjectDescriptionSection">
      <div className="ParagraphsContainer">
        <p className="FirstParagraph">{data.project_description_1}</p>
        <p className="SecondParagraph">{data.project_description_2}</p>
        <p className="ThirdParagraph">
          <img src={CTCLogo} alt="CTCLogo" />
          <span>{data.project_description_3}</span>
        </p>
      </div>
    </section>
  );
}
