import React, { useState, useEffect } from "react";
import { NewsCard } from "./NewsCard";
import "./NewsSection.scss";
import { useNavigate } from "react-router-dom";

export function NewsSection({ main, excerpt }) {
  const navigate = useNavigate();
  const [posts, setPosts] = useState(null);

  const fetchPosts = async () => {
    const res = await fetch("https://neucraft.ai/wp-json/wp/v2/posts?_embed");
    const _posts = await res.json();
    setPosts(_posts);
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  return (
    <section className="NewsSection">
      {main ? <h2>News</h2> : <h3>Other articles</h3>}
      <div className="NewsContainer">
        {posts &&
          posts.map((post, i) => {
            if (i > 1) return "";
            return (
              <NewsCard
                key={post.id}
                image={
                  post._embedded
                    ? post._embedded["wp:featuredmedia"][0].media_details.sizes
                        .medium_large.source_url
                    : ""
                }
                title={post.title.rendered}
                date={new Date(post.date).toLocaleDateString()}
                onClick={() => navigate(`/news/${post.id}`)}
                excerpt={excerpt || ""}
              />
            );
          })}
      </div>
      {main && (
        <div className="ReadMore" onClick={() => navigate("/news")}>
          Read more
        </div>
      )}
    </section>
  );
}
